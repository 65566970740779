import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useUser } from 'lib/hooks'

type MenuProps = {
  className: string
}

const Menu: React.FunctionComponent<MenuProps> = ({ className }: MenuProps) => {
  const router = useRouter()
  const { user } = useUser({})

  return (
    <nav className={`${className} hidden md:flex flex-col justify-between overflow-y-auto text-sm`}>
      <div>
        <span className="block mb-12 font-bold text-md">
          <Link href="/home" data-public passHref>
            管理画面
            <br />
            ニューヨーク育英学園
          </Link>
          <br />
        </span>
        <ul className="mb-16">
          <li className="mb-6">
            <Link
              href={'/students/'}
              data-public
              className={`text-coolGray-800 ${
                router.asPath.includes('student') &&
                !router.asPath.includes('register') &&
                'nav-active'
              }`}>
              学籍
            </Link>
          </li>
          <li className="mb-6">
            <Link
              href={'/admissions/'}
              data-public
              className={`text-coolGray-800 ${
                router.asPath.includes('admission') &&
                !router.asPath.includes('register') &&
                'nav-active'
              }`}>
              在籍
            </Link>
          </li>
          <li className="mb-6">
            <Link
              href={'/guardians/'}
              data-public
              className={`text-coolGray-800 ${
                router.asPath.includes('guardian') &&
                !router.asPath.includes('register') &&
                'nav-active'
              }`}>
              保護者
            </Link>
          </li>

          <li className="mb-6">
            <Link
              href={'/payments/'}
              data-public
              className={`text-coolGray-800 ${
                router.asPath.includes('payment') &&
                !router.asPath.includes('register') &&
                'nav-active '
              }`}>
              支払い
            </Link>
          </li>

          <li className="mb-6">
            <Link
              href={'/applications/'}
              data-public
              className={`text-coolGray-800 ${
                router.asPath.includes('application') &&
                !router.asPath.includes('register') &&
                'nav-active '
              }`}>
              出願
            </Link>
          </li>
        </ul>

        <ul className="mb-24">
          <li className="mb-6">
            <Link
              href={'/exports/'}
              data-public
              className={`text-coolGray-800 ${router.asPath.includes('exports') && 'nav-active'}`}>
              CSVエクスポート
            </Link>
          </li>

          <li className="mb-6">
            <Link
              href={'/register/guardian/'}
              data-public
              className={`text-coolGray-800 ${
                router.asPath.includes('register/guardian') && 'nav-active'
              }`}>
              保護者登録
            </Link>
          </li>
          <li className="mb-6">
            <Link
              href={'/register/student/'}
              data-public
              className={`text-coolGray-800 ${
                router.asPath.includes('register/student') && 'nav-active'
              }`}>
              学籍登録
            </Link>
          </li>
        </ul>

        <ul>
          <li>
            <a data-public href="/api/logout" className="block mb-6">
              ログアウト
              <br />
              <span className="text-sm">({user?.email})</span>
            </a>
          </li>
        </ul>

        <span className="text-gray-500 block py-2 text-xs">
          &copy; Japanese Children&apos;s Society, Inc.
        </span>
      </div>
    </nav>
  )
}

export default Menu
