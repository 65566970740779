import React from 'react'
import { useUser } from 'lib/hooks'

const Footer: React.FunctionComponent = () => {
  const { user } = useUser({})

  return (
    <footer className={`${user && 'md:hidden'} flex-none w-full`}>
      <span className="text-gray-400 text-center block py-2 text-xs">
        &copy; Japanese Children&apos;s Society, Inc.
      </span>
    </footer>
  )
}

export default Footer
