import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

import Header from './header'
import HeaderWithNav from './headerWithNav'
import Menu from './menu'
import Footer from './footer'

interface LayoutProps {
  children: React.ReactNode
}

const Layout = ({ children }: React.PropsWithChildren<LayoutProps>): React.ReactElement => {
  const router = useRouter()

  return (
    <>
      <Head>
        <title>管理画面 | ニューヨーク育英学園</title>
        <meta name="robots" content="noindex" />
      </Head>
      <div className="flex flex-col justify-between items-stretch min-h-screen">
        {router.asPath !== '/' && (
          <>
            <HeaderWithNav />
            <main className="flex-grow w-full items-stretch flex">
              <div className="flex flex-grow items-stretch">
                <Menu className="pt-12 pb-4 px-8 block h-full bg-green-50 max-h-screen sticky top-0  max-w-xs" />
                <div className="my-12 mx-4 md:mx-8 w-full">{children}</div>
              </div>
            </main>
            <Footer />
          </>
        )}
        {router.asPath === '/' && (
          <>
            <Header />
            <main>
              <div className="py-12">{children}</div>
            </main>
            <Footer />
          </>
        )}
      </div>
    </>
  )
}

export default Layout
