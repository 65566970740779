import React, { useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useUser } from 'lib/hooks'

const Header: React.FunctionComponent = () => {
  const router = useRouter()
  const { isLoading, user } = useUser({})
  const [isNavOpened, setIsNavOpened] = useState(false)

  const toddleNav = () => {
    setIsNavOpened(!isNavOpened)
  }
  const closeeNav = () => {
    setIsNavOpened(false)
  }

  if (isLoading) {
    return <> </>
  }

  return (
    <>
      <header
        className={`${
          user && 'md:hidden'
        } flex-none bg-green-600 flex items-center justify-center rounded-b-2xl shadow-xl`}>
        <div className="max-w-screen-lg w-full">
          <nav className="flex items-center justify-between h-16">
            <Link
              href="/"
              className="px-3 py-2 text-lg font-semibold text-white tracking-wide opacity-100">
              ニューヨーク育英学園
            </Link>
            <ul className="flex">
              {router.asPath !== '/' ? (
                <>
                  <li>
                    <button className="text-white px-2 py-2 opacity-100" onClick={toddleNav}>
                      メニュー
                    </button>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <a className="text-white px-2 py-2 mr-2" href="/api/login">
                      ログイン
                    </a>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </div>
      </header>
      <nav
        className={`z-50 fixed w-screen h-full min-h-screen bg-green-50 overflow-scroll ${
          !isNavOpened && 'hidden'
        }`}>
        <div className="block my-4 mx-4">
          <div className="text-right block">
            <span className=" text-md cursor-pointer" onClick={closeeNav}>
              閉じる
            </span>
          </div>

          <span className="block mb-12 font-bold text-md">
            <Link href="/" passHref>
              管理画面
              <br />
              ニューヨーク育英学園
            </Link>
            <br />
          </span>

          <ul className="mb-16">
            <li className="mb-8">
              <Link
                href={'/students/'}
                data-public
                className={`text-coolGray-800 ${
                  router.asPath.includes('student') &&
                  !router.asPath.includes('register') &&
                  'nav-active'
                }`}>
                学籍
              </Link>
            </li>
            <li className="mb-8">
              <Link
                data-public
                href={'/guardians/'}
                className={`text-coolGray-800 ${
                  router.asPath.includes('guardian') &&
                  !router.asPath.includes('register') &&
                  'nav-active'
                }`}></Link>
            </li>
            {/* <li className="mb-8">
              <Link
                href={'/summer-winter-schools/'}
                data-public
                className={`text-coolGray-800 ${
                  router.asPath.includes('summer-winter-school') &&
                  !router.asPath.includes('register') &&
                  'nav-active '
                }`}>
                サマー・ウィンタースクール
              </Link>
            </li> */}

            <li className="mb-8">
              <Link
                href={'/admissions/'}
                data-public
                className={`text-coolGray-800 ${
                  router.asPath.includes('program') &&
                  !router.asPath.includes('register') &&
                  'nav-active '
                }`}>
                在籍
              </Link>
            </li>

            <li className="mb-8">
              <Link
                href={'/guardians/'}
                data-public
                className={`text-coolGray-800 ${
                  router.asPath.includes('program') &&
                  !router.asPath.includes('register') &&
                  'nav-active '
                }`}>
                保護者
              </Link>
            </li>

            {/* <li className="mb-8">
              <Link
                href={'/buses/'}
                data-public
                className={`text-coolGray-800 ${
                  router.asPath.includes('bus') &&
                  !router.asPath.includes('register') &&
                  'nav-active '
                }`}>
                バス
              </Link>
            </li> */}

            {/* <li className="mb-8">
              <Link
                href={'/orders/'}
                data-public
                className={`text-coolGray-800 ${
                  router.asPath.includes('order') &&
                  !router.asPath.includes('register') &&
                  'nav-active '
                }`}>
                注文 (レンタル等)
              </Link>
            </li> */}

            <li className="mb-8">
              <Link
                href={'/payments/'}
                data-public
                className={`text-coolGray-800 ${
                  router.asPath.includes('payment') &&
                  !router.asPath.includes('register') &&
                  'nav-active '
                }`}>
                支払い
              </Link>
            </li>

            {/* <li className="mb-8">
              <Link
                href={'/agreements/'}
                data-public
                className={`text-coolGray-800 ${
                  router.asPath.includes('agreement') &&
                  !router.asPath.includes('register') &&
                  'nav-active '
                }`}>
                届出・同意書
              </Link>
            </li> */}

            <li className="mb-8">
              <Link
                href={'/applications/'}
                data-public
                className={`text-coolGray-800 ${
                  router.asPath.includes('application') &&
                  !router.asPath.includes('register') &&
                  'nav-active '
                }`}>
                出願
              </Link>
            </li>
          </ul>

          <ul className="mb-24">
            <li className="mb-8">
              <Link
                href={'/register/guardian/'}
                data-public
                className={` ${router.asPath.includes('register/guardian') && 'nav-active'}`}>
                保護者登録
              </Link>
            </li>
            <li className="mb-8">
              <Link
                href={'/register/student/'}
                data-public
                className={` ${router.asPath.includes('register/student') && 'nav-active'}`}>
                園児・児童・生徒登録
              </Link>
            </li>
          </ul>

          <ul>
            <li>
              <a href="/api/logout" className="block mb-6">
                ログアウト
                <br />
                <span className="text-sm">({user?.email})</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Header
