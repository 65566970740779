import { useState, useCallback, useEffect } from 'react'
import Img from 'next/image'

import Router from 'next/router'
import Layout from '../components/layout'
import { Magic } from 'magic-sdk'
import { OAuthExtension } from '@magic-ext/oauth'
import { useUser } from 'lib/hooks'

const Login = () => {
  const { isFinished = false, user } = useUser({ redirectIfFound: '/home' })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoadFinished, setIsLoadFinished] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    if (isFinished) {
      setIsLoadFinished(true)
    }
  }, [isFinished])

  const googlog = useCallback(async () => {
    try {
      const magic = new Magic(process.env.NEXT_PUBLIC_MAGIC_PUBLISHABLE_KEY, {
        locale: 'ja',
        extensions: [new OAuthExtension()],
      })
      const res = await magic.oauth.loginWithRedirect({
        provider: 'google',
        redirectURI:
          (process.env.NEXT_PUBLIC_LOCAL_DEV_URL &&
            `http://${process.env.NEXT_PUBLIC_LOCAL_DEV_URL}/callback`) ||
          `https://${process.env.NEXT_PUBLIC_VERCEL_URL}/callback`,
      })
    } catch (error) {
      console.error('An unexpected error happened occurred:', error)
      setErrorMsg(error.message)
    }
  }, [])

  return (
    <>
      {!isLoadFinished && <></>}
      {isLoadFinished && !user && (
        <Layout>
          <div className="flex flex-col items-center justify-center mx-4">
            <button
              className="flex items-center  border border-[#4185f4] border-solid rounded hover:border-blue-600"
              onClick={() => googlog()}>
              <div className="ml-2 flex items-center">
                <Img
                  src="/google_logo.svg"
                  layout="fixed"
                  width="26"
                  height="26"
                  alt="Google Logo"
                />
              </div>
              <span className="ml-2 py-3 px-2 font-semibold text-sm text-gray-50 bg-[#4185f4]">
                Google アカウントでログイン
              </span>
            </button>
            <p className="text-gray-900 text-sm mt-8 mb-2 max-w-sm text-center">
              管理用のログインページです。 <br />
              保護者の方は下記URLよりログインください。
            </p>
            <a className="text-blue-600 underline" href="https://id.nyikuei.org/">
              https://id.nyikuei.org
            </a>
          </div>
        </Layout>
      )}
    </>
  )
}

export default Login
